<template>
    <v-container style="max-width: 600px">
        <div v-if="loading">
            <v-skeleton-loader
                type="card-heading,list-item-two-line,list-item-two-line,list-item-three-line"
                class="mb-3"
            >
            </v-skeleton-loader>
            <v-skeleton-loader
                type="card-heading,list-item-two-line,list-item-two-line,list-item-three-line"
                class="mb-3"
            >
            </v-skeleton-loader>
            <v-skeleton-loader
                type="card-heading,list-item-two-line,list-item-two-line,list-item-three-line"
                class="mb-3"
            >
            </v-skeleton-loader>
            <v-skeleton-loader
                type="card-heading,list-item-two-line,list-item-two-line,list-item-three-line"
                class="mb-3"
            >
            </v-skeleton-loader>
        </div>
        <div v-else-if="leads.length > 0">
            <div v-for="lead in leads" :key="lead.identifier_code">
                <v-card class="mb-3" :to="`/leads/${lead.identifier_code}`">
                    <v-card-title>
                        {{ lead.identifier_code }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ lead.external_reference }}
                    </v-card-subtitle>

                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Seguradora
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{ lead.insurer }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Segurado
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{ lead.insured }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="lead.participants.length > 0">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Participantes encontrados
                                </v-list-item-subtitle>
                                <v-list-item-title class="text-wrap">
                                    <v-chip x-small label class="mr-1 mb-1" v-for="participant in lead.participants" :key="participant.id">
                                        {{ participant.name }}
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </div>
        <div v-else style="height: 50vh" class="d-flex align-center justify-center">
            <EmptyState message="Nenhuma lead encontrada" color="transparent" />
        </div>
    </v-container>
</template>

<script>
    import { search } from '@/services/search';
    import EmptyState from "@/components/EmptyState";

    export default {
        components: {
            EmptyState
        },

        data() {
            return {
                leads: [],
                loading: false
            }
        },

        methods: {
            async search(query) {
                this.leads = [];
                this.loading = true;
                const response = await search(query);
                if(response.leads.length === 1) {
                    this.$router.replace(`/leads/${response.leads[0].identifier_code}`)
                }
                this.leads = response.leads;
                this.loading = false;
            }
        },

        watch: {
            '$route.query.q': {
                handler: function(q) {
                    this.search(q);
                },
                deep: true,
                immediate: true
            }
        },

        computed: {
            query() {
                return this.$route.query.q || ''
            }
        },

        async created() {
            if(this.query) {
                await this.search(this.query);
            }
        }
    }
</script>